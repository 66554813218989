//
// Layout Config
//



// Layout
$kt-aside-default-width: 256px;
$kt-aside-minimize-width: 74px;
$kt-aside-offcanvas-width: 255px;


// Layout Skins
$kt-layout-skins: ( dark: #307a55, light: #FCF7F3 );

// content bg color
$kt-content-bg-color: #FCF7F3;

// Border Radius
$kt-border-radius: 4px;

// Page padding
$kt-page-padding: (
	desktop_top: 24px,
	desktop: 0px,
	mobile: 8px
);

// Page container width
$kt-page-container-width: 1380px;
