//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//
/*MessTpl*/

/*Responsive*/
table.mail-cta-button {
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

table.mail-cta-button td {
    border-radius: 5px;
    background: #fd5e0c;
    text-align: center;
}

.mt-height-100 {
    height: 100% !important;
}

.mt-dialog-child-form mat-sidenav-container.mat-drawer-container.mat-sidenav-container.ng-star-inserted {
    height: 100% !important;
}

.mt-dialog-child-form .mat-tab-body-wrapper {
    height: 100% !important;
}

.mt-dialog-child-form kt-portlet-header.kt-portlet__head.kt-portlet__head--lg {
    position: relative !important;
    top: 0 !important;
    width: 100% !important;
}

.mt-dialog-child-form kt-portlet-body.kt-portlet__body {
    height: 100%;
    overflow: unset !important;
}

.mt-dialog-child-form kt-portlet-body.kt-padding-0.kt-portlet__body {
    overflow: unset !important;
    max-height: 100% !important;
}

.mat-dialog-content.mat-dialog--mt {
    margin-bottom: 6px;
    /*max-height: 80vh !important;
    height: 100%;

    .kt-portlet .kt-portlet__body {
        max-height: 80vh !important;
    }*/
}

.mt-height-90 {
    height: 90% !important;
}

.warning-icon {
    margin-left: 5px;
}

.warning-icon img {
    vertical-align: baseline;
}

table.mail-cta-button td a {
    background: #fd5e0c;
    border: 11px solid #fd5e0c;
    font-family: arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    mso-height-rule: exactly;
    text-decoration: none;
    display: block;
    border-radius: 5px;
}

table.mail-cta-button td a {
    color: white;
}

.mt-vertical-middle {
    margin: auto 0 !important;
}

table.banner td {
    padding: 30px 0;
}

td.content-template {
    padding-top: 0;
    padding-bottom: 0;
}


td.colunm-info {
    padding-right: 15px !important;
    width: 33%;
}

.wrap-booking-detail.service table.table-booking-detail.table td {
    min-width: 30px;
    padding-left: 20px;
}

.title-summary-table {
    border-bottom: 2px dashed #d3d3d9;
    color: #606062;
}

.mt-term-conditions {
    font-size: 14px;
    line-height: 16.1px;
    text-align: justify;
    margin-top: 48px;
}

td.total-booking-detail {
    width: 165px;
}

.term-link {
    text-decoration: underline;
    color: #000000 !important;
}

table.table-booking-detail.table td.first-colunm {
    padding-left: 0px !important;
}

.cta-booking {
    text-align: center;
    background-color: #fd5e0c;
    border-radius: 5px;
    margin: 48px 0;
    max-height: 50px;
    padding: 11px;
    width: 100%;
}

.cta-arrow {
    position: relative;
    font-size: 1.62rem;
    top: 3px;
    font-weight: 500;
    color: #ffffff;
}

.quantity-summary {
    width: 95px;
}

.cta-arrow-mail {
    display: none;
}

ckeditor a span {
    color: #017C38 !important;
}

table.banner td h2 {
    text-align: right;
    text-transform: uppercase;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.cta-booking-text {
    padding: 11px;
    font-weight: 700;
    font-size: 24px;
    line-height: 27.6px;
    color: #ffffff;
}

.title-order-summary {
    font-size: 18px;
    line-height: 20.7px;
    font-weight: 400;
    color: #000;
}

.summary-status {
    color: #ff5e00;
    font-weight: 700 !important;
}

.booking-number {
    font-weight: 700;
    font-size: 36px;
    line-height: 41.4px;
}

.messstpl-title-block {
    padding: 48px 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 27.6px;
    color: #017C38;
}

table.header.subheader_table.order_summary {
    font-weight: 700;
    line-height: 1.2;
    font-size: 18px;
    padding: 10px 30px;
}

.page > p {
    margin: 0;
}

@media screen and (max-width: 1023px) {
    table.subheader_table.order_summary {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1398px) {
    table.subheader_table.order_summary {
        margin-left: -30px;
        width: 108.6% !important;
    }
}

@media screen and (min-width: 1399px) {
    table.subheader_table.order_summary {
        width: 111.2% !important;
    }

    td.content-template .page {
        padding: 0 30px;
    }
}

figure.table td {
    vertical-align: top;
    min-width: 230px;
}

.countSession {
    font-size: 32px;
}

.contentMessage .page {
    width: 600px;
}

.contentMessage .page figure.table {
    /*padding: 0 30px;*/
}

div#printContent .header-templt,
.banner-templt.row,
table.subheader_table.order_summary,
.top-booking-detail {
    /*margin: 0 -30px;*/
}

.page .table td {
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
}

.summary-title {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    line-height: 20.7px;
    padding-bottom: 16px;
    padding-top: 24px;
}

.float-right {
    float: right;
}

.view-detail-booking {
    text-decoration: underline;
    font-size: 18px;
    line-height: 20.7px;
    color: #017c38;
}

.page figure.table table {
    width: 100%;
}

.title-booking-detal {
    font-weight: 700;
    font-size: 24px;
    line-height: 27.6px;
    color: #000000;
}

.contentMessage {
    width: 100%;
    background-color: #e3e3e3;
}

.contentMessage .page {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    line-height: 24px;
    font-size: 18px;
}

@media print {
    .contentMessage .page {
        max-width: 100%;
    }
}

.productsession-number.col-md-1 {
    text-align: center;
    font-size: 30px;
    padding: 45px 0 45px 0;
}

.datetime-header {
    float: left;
}

.company-name + p {
    margin: 0;
}

.company-name {
    background: #f3f3f3;
    padding: 30px;
    text-align: center;
    margin: 0 -30px;
}

.terms-block {
    padding: 30px 30px 20px 30px;
}

.align-top-right {
    text-align: right;
    vertical-align: top;
}

.align-top {
    vertical-align: top;
}

.table-wrap-content {
    padding: 0 30px;
}


hr.hrbegin-end.hr-end {
    margin: 0px 30px;
    border-top: 1pt solid #a3a3a3;
}

.term-hr {
    border: 1px solid #d3d3d9;
    margin-bottom: 5px;
}

.margin-r-15 {
    margin-right: 15px;
}

.mt-padding-bt-30 {
    padding-bottom: 30px;
    padding-top: 40px;
}

.terms-block h5 {
    padding: 10px 0;
}

.wrap-terms-block {
    margin: 0 -30px;
    background-color: #f3f3f3;
}

.wrap-booking-detail > h5 {
    padding: 50px 0 10px 0;
}

div#printContent {
    /*padding: 0 30px;*/
}

hr.hrbegin-end {
    margin-top: 0;
    /* margin: 0 -30px; */
}

.wrap-top-header {
    text-align: center;
}


@page {
    margin-top: 15mm;
    margin-bottom: 15mm;
}

@media print {
    body {
        margin: 0;
    }

    /* Styles go here */
    div#printContent {
        padding: 0 1rem;
    }

    .page-header,
    .page-header-space,
    .page-footer,
    .page-footer-space {
        height: 0px;
    }


    .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        border-top: 1px solid black;
        /* for demo */
        background: yellow;
        /* for demo */
    }

    .page-header {
        position: fixed;
        top: 0mm;
        width: 100%;
        border-bottom: 1px solid black;
        /* for demo */
        background: yellow;
        /* for demo */
    }

    .page {
        page-break-after: always;
    }
}

@media screen {

    .page-header,
    .page-header-space {
        display: none;
    }

    .page-footer {
        display: none;
    }

    #printSection {
        display: none;
    }

    .top-header-templt {
        display: none;
    }

    .header-templt {
        color: white;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    .header-templt {
        color: black;
    }

    .top-header-templt {
        width: 100%;
        display: block;
        position: fixed;
        top: 0px;
    }

    #printSection,
    #printSection * {
        visibility: visible;
    }

    #printSection {
        position: absolute;
        left: 0;
        top: 0;
    }
}

table.subheader_table.order_summary {
    border-collapse: separate;
    margin-left: -30px;
}



td.col-order-summary {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    width: 33%;
    /*border-top: 1pt solid #62ba37 !important;*/
    vertical-align: top;
}

small.title-booking.title-order-summary {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: #62ba37;
}

.title-booking {
    color: #62ba37;
}

td.col-order-summary h3 {
    font-size: 1rem;
    margin: 0;
}

tr.row-total-booking-detail td {
    border-bottom: #fff !important;
}

.table-booking-detail.table td {
    border-top: #fdfdfd;
}

.table-booking-detail.table {
    line-height: 20.7px;
    border-bottom: 2px dashed #d3d3d9;
}

.table-booking-detail.table ol {
    margin-bottom: 0;
}

td.col-order-summary h2 {
    font-size: 1.3rem;
    margin: 0;
    min-width: 100px;
    color: #62ba37;
}

.top-booking-detail {
    background-color: #f3f3f3;
    height: 20px;
}

.header-booking-detail {
    padding-top: 25px;
}

.order-summary.row {
    padding: 0px 30px 15px 30px;
    background-color: #fafafa;
    margin: 0;
}

.order-summary .col-md-3 {
    padding: 10px 10px 10px 0;
    word-break: break-word;
}

.wrap-participant {
    padding: 20px 0 10px 0;
}

.parti-detail {
    margin-bottom: 7px;
}

.wrap-ordertitle {
    width: fit-content;
    border-top: solid 2px;
    line-height: 1.2;
}

/*.messTemplate-content p {
    padding: 0 30px;
}*/
.wrap-ordertitle span {
    font-weight: bold;
    font-size: 20px;
}

.wrap-ordertitle span.total-amount {
    font-size: 23px;
}

.modal-body.messTpl-content {
    background-color: #f3f3f3;
}

.header-templt {
    margin-left: -30px;
    margin-right: -30px;
    text-align: center;
    background-color: #307a55;
    margin-top: -12px;
}

.messTemplate-content {
    background-color: #fff;
}

.messTplName-wrap {
    position: relative;
}

.banner-templt img {
    padding: 10px 0px;
}

table.table-booking-detail.table th {
    border-top: none;
}

/*.wrap-booking-detail {
    margin-left: 30px;
    margin-right: 30px;
}*/
span.product-name-booking-detail {
    font-size: 28px;
    font-weight: bold;
}

.total-booking-detail {
    text-align: right;
}

td.border-none-booking-detail {
    border-bottom: #fff !important;
}

hr.hr-booking-detail {
    margin: .1rem 0;
    width: 100%;
    border-top: 2pt solid rgb(98 186 55);
}

.header-booking-detail h5 {
    font-weight: bold;
}

.banner-booking-detail {
    background-color: #307a55;
    color: white;
    padding: 30px;
    margin: 30px -30px;
}

h3.messTplName {
    margin: 0;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    right: 2%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*End MessTpl*/
//ImportFom
.kt-form.action-log.import-form {
    width: 100%;
}

.kt-form.kt-form--group-seperator-dashed .kt-form__group.hr-mTpl {
    padding-top: 0px;
    padding-bottom: 10px;
}

.messTpl mat-header-row,
.messTpl mat-row {
    border: none;
    min-height: 40px;
}

.file-import {
    padding-bottom: 25px;
}

.mess-personalise-wrap {
    padding-bottom: 20px;
}

.mess-personalise {
    padding-left: 15px;
    color: #307a55;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
    position: relative;
    bottom: -3px;
}

.kt-form.kt-form--group-seperator .kt-form__group,
.kt-form.kt-form--group-seperator-dashed .kt-form__group.status {
    padding-top: 5px;
    padding-bottom: 10px;
}

//end importForm
.mat-table__wrapper {
    border-style: solid solid none;
    border-width: 1px;
    border-color: #d8dce6;
    border-radius: 4px 4px 0 0;
    font-size: 0.875rem;

    .mat-column-vinCode.cdk-column-productCategoryName {
        flex: 1;
    }

    .action-row {
        display: flex;
        background-color: white;
        padding-left: 28px;

        .mt-filter-row {
            margin-bottom: 0px;
        }
    }

    &--no-border {
        border: unset;
    }
}


.mat-table__bottom {
    background-color: white;
    border-style: none solid solid;
    border-width: 1px;
    border-color: #d8dce6;
    border-radius: 0 0 4px 4px;
    min-height: 45px;
}

.message-detail {
    flex: .3;
    padding-left: 30px;
}

.mat-column-vinCode.column-help.actionlog {
    flex: 0 0 240px;
}

mat-cell#note {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 50px;
    margin-bottom: 8px;
    -webkit-box-orient: vertical;
}

mat-cell#noteView {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 50px;
    margin-bottom: 8px;
    -webkit-box-orient: vertical;
}

mat-cell#note:hover {
    text-overflow: clip;
    white-space: normal;
    display: flex;
    word-break: break-word;
    height: auto;
    min-height: 58px;
    align-items: unset;
    margin-bottom: auto;
}

.messTpl-blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-top: .5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border: 1px solid #ccc;
    border-left: 5px solid #ccc;
}

.kt-form.action-log {
    width: 650px;
}

.mat-column-vinCode.column-help {
    flex: 1 0 170px;
}

.mat-column-vinCode.column-help.last {
    word-break: break-word;
}

.service-label {
    padding-top: 15px;
}

.srv-label {
    padding-right: 8px;
}

.service-label .mat-checkbox#mat-checkbox-2 {
    padding: 0 4px;
}

.srv-multy label.mat-checkbox-layout {
    padding-top: 15px;
}

.example-radio-group {
    flex-direction: row;
}

.kt-form.kt-form--group-seperator-dashed .kt-form__group {
    border-bottom: unset;
}

/*.form-group {
    margin-bottom: 4px;
}*/

.kt-form.kt-form--group-seperator .kt-form__group,
.kt-form.kt-form--group-seperator-dashed .kt-form__group {
    padding-top: 24px;
    padding-bottom: 0px;
}

/*.kt-form .kt-form__filtration {
    margin-bottom: 20px;
}*/

/*.kt-portlet .kt-portlet__body {
    padding-top: 24px;
}*/

mat-form-field.mat-form-field-fluid.mat-form-field.ng-tns-c17-7.mat-primary.mat-form-field-type-mat-input.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label.ng-untouched.ng-pristine.ng-valid {
    padding-top: 10px;
}

.my-custom-style {
    padding: 1rem 0.5rem;
    color: kt-brand-color();

    .my-custom-element {
        margin-left: 1rem;
    }
}

@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}

@import '@angular/material/theming';

$custom-typography: mat-typography-config($font-family: '"Source Sans Pro", Helvetica Neue;', $button: mat-typography-level(14px, 14px, 600));
@include mat-core($custom-typography);

.mat-table {
    font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
    padding: 0 16px;
    /*overflow: auto;
    max-height: 732px;*/
}

.mat-grid-tile .mat-figure {
    justify-content: space-evenly !important;
}

.mat-grid-tile-left .mat-figure {
    left: 24px !important;
    justify-content: left !important;
}

/*.mat-expansion-panel-body {
    padding: 0!important;
}*/
.mat-grid-list {
    margin-bottom: 6px;
}

.mt-login-background {
    background-image: url(/assets/media/bg/bg-oxalis-fullscreen.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.mt-login-background .mat-stepper-horizontal {
    background-color: transparent;
}
.mt-login {
    color: #e8ecfa;
}
.mt-login .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #e8ecfa;
}
.mt-login .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #e8ecfa;
}
.mt-login .mat-form-field.mat-focused .mat-form-field-label {
    color: #e8ecfa;
}
.mt-login .mat-form-field.mat-focused .mat-form-field-ripple {
    color: #e8ecfa;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #e8ecfa !important;
}
.mt-login .mat-input-element {
    caret-color: #e8ecfa;
}*/


.mt-tab-enable .mat-tab-label.mat-tab-disabled {
    color: #000 !important;
    cursor: pointer !important;
}

.mat-tab-label {
    min-width: unset !important;
}

.mt-form-group-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mt-radio-group {
    display: inline-flex;
    flex-direction: column;
}

//accordion
.booking-accordion .btn.btn-link {
    width: 100% !important;
    text-align: left;
}

.accordion .card .card-header .btn.btn-link:hover,
.btn.btn-link:focus {
    background-color: transparent;
    color: unset;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0rem !important;
}

.mat-tooltip {
    background-color: kt-base-color(background, 4);
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #017c38 !important;
    background: #017c38 !important;
}

.multiselect-dropdown .multiselect-item-checkbox input + div:before {
    border: 2px solid #017c38 !important;
}

.multiselect-dropdown .multiselect-item-checkbox input:checked + div:before {
    background: #017c38 !important;
}

.multiselect-dropdown {
    min-width: 400px;
}

.form-control.custom-select.multi-select span.dropdown-down,
.form-control.custom-select span.dropdown-up {
    border-color: transparent !important;
}

.multi-select .multiselect-dropdown {
    min-width: unset;
}

.form-control.custom-select.multi-select {
    padding-right: 8px !important;
}

.form-control.custom-select.multi-select .dropdown-list {
    margin-top: 30px !important;
}

.form-control.custom-select.multi-select span.dropdown-btn {
    display: block !important;
    border: unset !important;
    padding: 0 !important;
    line-height: unset !important;
}

.right-filter-menu-title {
    color: kt-base-color(label, 6);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2rem;
    height: 2rem;
    padding: 0 16px;
    text-align: left;
}

button.mt-menu-item {
    line-height: 2rem;
    height: 2rem;

    .mat-icon {
        margin-right: 8px;
    }
}

.columnSelect div.mat-select-arrow-wrapper {
    display: none;
}

.columnSelect.mat-select {
    display: inline;
}

.panelColumnSelect {
    overflow: hidden !important;
    margin-top: 32px;
    transform: translateX(-40px) !important;

    .customPanel {
        max-height: 200px;
        overflow: auto;
        padding: 8px 0px;
    }

    footer {
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        /*height: 56px;*/
        padding: 8px;
        /*text-align: right;*/
    }
}

.mat-select-panel .customPanel .mat-optgroup-label,
.mat-select-panel .customPanel .mat-option,
.mat-autocomplete-panel .mat-option {
    white-space: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: unset;
    height: auto;
    color: kt-base-color(label, 6);

    .mat-option-text {
        padding: 6px 0;
    }
}

.mat-header-row {
    min-height: 50px;

    mat-header-cell:first-of-type {
        padding-left: 8px;
    }
}

.mat-header-cell {
    color: kt-base-color(label, 6);
    font-size: 0.875rem;
    font-weight: 600;
}

.mat-cell {
    color: kt-base-color(label, 6);
    font-weight: 400;
}

.mat-row:active {
    background-color: #EBF5EF;
}

.mat-row.active {
    background-color: kt-base-color(background, 7);
}

.mat-row:hover {
    background-color: #F6F6F6;
}

.mat-paginator {
    color: kt-base-color(label, 6);
    font-size: 0.875rem;
    font-weight: 500;
}

.mat-icon-button[disabled][disabled] {
    color: kt-base-color(label, 3);
}

.mat-row {
    mat-cell:first-of-type {
        padding-left: 8px;
    }
}

.mat-table__message {
    background-color: white;
}

.kt-input-icon {
    .mat-select-arrow {
        border: none;
    }

    .form-control {

        .mat-select-placeholder,
        .mat-select-value {
            color: kt-base-color(label, 6);
        }
    }
}

.mt-custom-panelselect {
    color: kt-base-color(label, 6);
    margin-top: 36px;
    padding: 8px 0px !important;

    .mat-option {
        color: kt-base-color(label, 6);
        height: 2rem !important;
    }
}

.mat-autocomplete-panel {
    padding-top: 8px;
    padding-bottom: 8px;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

/**:hover::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}*/

::-webkit-scrollbar-thumb {
    background: kt-base-color(background, 2);
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: kt-base-color(background, 3);
    border-radius: 0px;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: kt-base-color(label, 2);
}

.mt-link {
    color: currentColor;
}

.mt-link:hover {
    color: unset;
    text-decoration: underline !important;
}

/*!.mat-row .mat-button.mat-button-action[aria-expanded="true"] {
    background-color: #EBF5EF;
}*/

.mat-column-actions {
    display: flex;
    justify-content: flex-end;
    min-width: 100px !important;
}

.mat-column-status {
    justify-content: center;
}

.mat-table__wrapper .mat-table .mat-cell.mat-column-actions:last-child {
    padding-right: 8px;
}

.mat-row:hover .mat-button-action {
    display: inline-block;
}

.mat-button.mat-button-action[aria-expanded="true"] {
    display: inline-block;
    border-radius: 3px;
    background-color: #CFE6D9;
}

.mat-button.mat-button-action {
    display: none;
    min-width: unset;
    line-height: unset;
    padding: 0 4px !important;
}

.mat-column-mtCode {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.875rem;
    font-weight: 600;
    color: kt-base-color(label, 4);

    .mt-link {
        color: kt-base-color(label, 4);
    }
}

.kt-portlet__head-label {
    /*min-width: 150px;*/

    .mt-filter-row {
        padding-left: 24px;
        margin-bottom: 0px;

        .mt-search-input {
            width: 140px;
        }
        /*.form-control {
            height: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            padding: 0.5rem 0.75rem;
            color: kt-base-color(label, 6);
            border-color: kt-base-color(border, 2);
            background-color: kt-base-color(background, 5);
        }*/

        .dropdown-menu {

            .form-control,
            .form-control:focus {
                border: none;
                box-shadow: none;
            }

            .form-control {
                margin-top: 8px;
                padding: 7px 16px;
            }

            .kt-input-icon__icon {
                padding: 0 16px;
            }

            .mat-list-base {
                padding: 0px;
            }

            footer {
                padding: 16px;
                text-align: right;

                .mat-stroked-button:not([disabled]) {
                    border-color: currentColor;
                    line-height: 29px;
                }

                .kt-link {
                    font-size: 0.875rem;
                    color: unset;
                    font-weight: 600;
                    margin-right: 24px;

                    &:hover:after {
                        border-bottom: none;
                        opacity: 0;
                    }
                }
            }
        }

        .kt-input-icon__icon {
            width: unset;
            padding: 0 0.5rem;
            /*z-index: -1;*/
            .material-icons {
                color: kt-base-color(label, 4);
                font-size: 20px;
            }
        }

        ::placeholder {
            color: kt-base-color(label, 4);
        }

        .form-control:focus {
            border: 1px solid kt-base-color(shape, 2);
        }

        select {
            -o-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .mat-icon-button--label {
            color: kt-base-color(label, 6);
            font-size: 0.875rem;
            font-weight: 400;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px 4px;
            line-height: 1;
            border-radius: 5px;

            .mat-button-wrapper {
                display: flex;
                align-items: center;
                padding: 8px 4px;
            }

            .material-icons {
                font-size: 15px;
            }

            .mat-icon {
                height: 16px;
                width: 16px;
                line-height: 1;
                margin-right: 4px;
            }

            &.brand {
                color: kt-base-color(shape, 2);
            }

            &.brand:hover {
                color: kt-base-color(shape, 2);
            }

            &:active {
                background-color: kt-base-color(background, 7);
            }
        }

        .mat-list-option {
            min-height: 32px;
            height: auto;
        }

        .mat-list-text {
            font-size: 0.875rem;
            font-weight: 400;
            color: kt-base-color(label, 6);
        }

        .single-select {
            &.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
                padding-left: 0px;
            }
        }

        .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
            padding-left: 8px;
        }

        .mat-list-base .mat-list-item .mat-list-item-content,
        .mat-list-base .mat-list-option .mat-list-item-content {
            padding: 4px 16px;
        }
    }
}

.kt-portlet__head-toolbar {
    .mt-filter-row {
        padding-left: 16px;
        margin-bottom: 0px;
    }
}

.mt-filter-row {
    display: table-row;
    float: left;

    .mt-filter-inside {
        display: table-cell;
        vertical-align: middle;
        padding: 0 8px;
        font-size: 0.875rem;

        .show {
            .mat-icon-button--label {
                background-color: kt-base-color(background, 6);
            }
        }

        a:hover {
            color: unset;
        }

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    &--right {
        float: right;

        .mt-filter-inside {
            padding: 0 4px;
        }
    }

    &--input-group {
        .mt-filter-inside:last-child {

            .custom-select,
            .form-control {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .mt-filter-inside:not(:last-child) {

            .custom-select,
            .form-control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .custom-select:focus,
        .form-control:focus {
            z-index: 3;
        }
    }

    .mt-select {
        border: none;
        color: kt-base-color(label, 6);
        font-size: 0.875rem;
        font-weight: 500;
    }
    /*mat-select .mat-select-value {
        width: auto;
        min-width: 2ch;
        max-width: 25ch;
    }*/
    mat-select .mat-select-placeholder {
        width: auto;
        min-width: 10ch;
    }

    .kt-input-icon__icon.mt-arrow {
        width: auto;
        right: 13px;
    }

    .mt-arrow .material-icons {
        color: kt-base-color(label, 6);
        font-size: 1.25rem;
    }

    .mat-select-arrow-wrapper {
        width: 20px;
    }

    .mt-search-input {
        width: 240px;
    }

    .right-column {
        text-align: right;
        color: kt-base-color(label, 5);
    }

    .mat-icon-button {
        mat-select .mat-select-value {
            width: 100%;
            min-width: 0;
            max-width: 0;
        }
    }

    &.new-row {
        flex-basis: 100%;
        padding-left: 0px;
        padding-top: 24px;

        .mt-search-input {
            width: 240px;
        }

        .mt-filter-product {
            width: 300px;
        }
    }
}

.kt-portlet__head-toolbar .mat-stroked-button .mat-button-wrapper > * {
    vertical-align: baseline;
}

.mat-drawer-container {
    background-color: kt-base-color(background, 5);

    .mat-tab-body-wrapper {
        height: calc(100vh - 199px);
        display: block;
        overflow: auto;
        padding-bottom: 12px;
    }

    .mat-sidenav-container .mat-tab-body-wrapper {
        height: calc(100vh - 133px);
        display: block;
        overflow: auto;
        padding-bottom: 12px;
    }

    .mat-drawer-tab-inner .mat-tab-body-wrapper {
        overflow: hidden;
        height: 100%;
    }
}

.mt-grid {
    height: calc(100vh - 80px);
    display: flex;
    border: 1px solid kt-base-color(border, 1);
    border-radius: 3px;

    .mt-grid__aside {
        flex: 0 0 288px;
        display: flex;
        width: 288px;
        border-right: 1px solid #eeeef4;
        overflow: hidden;

        .mat-table {
            height: calc(100vh - 130px);
            overflow: auto;
        }

        .mat-paginator-sticky {
            bottom: 0px;
            position: sticky;
            z-index: 10;
            border-top: 1px solid kt-base-color(border, 1);

            .mat-form-field-appearance-legacy .mat-form-field-wrapper {
                padding-bottom: 8px;

                .mat-form-field-infix {
                    border-top: 0px;
                }
            }

            .mat-form-field-appearance-legacy .mat-form-field-underline {
                bottom: 12px;
            }
        }

        .mat-drawer-inner-container {
            overflow: hidden;
        }

        .mat-header-row {
            margin-left: -16px;
            margin-right: -16px;

            .mat-header-cell:first-of-type {
                padding-left: 24px;
            }
        }

        .mat-footer-row {
            border: none;

            .mat-footer-cell {
                justify-content: center;
            }
        }

        .mat-row mat-cell {
            flex-direction: column;
            align-items: flex-start;
            padding-right: 8px;
        }

        .mt-cell-top {
            width: 100%;
            padding: 8px 0 2px;
        }

        .mt-cell-bottom {
            padding: 2px 0 8px;
        }

        .mt-item__code {
            font-family: 'Roboto Mono', monospace;
            font-size: 0.875rem;
            font-weight: 600;
            color: kt-base-color(label, 4);
            float: left;
        }

        .mt-item__icon {
            color: kt-base-color(label, 4);
            height: 20px;
            width: 20px;
            font-size: 20px;
            padding-left: 16px;
            cursor: default;
        }

        .mt-item__status {
            float: right;
        }

        .mt-item__info {
            float: left;
        }

        .mat-paginator-range-label {
            margin: 0px;
        }

        .mat-paginator-page-size-select {
            width: 36px;
        }

        .mat-paginator-container {
            min-height: 50px;
            max-height: 50px;
        }
    }

    .mt-grid__wrapper {
        background-color: #ffffff;
        overflow: hidden;

        ::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    }
}

.align-items-center {
    display: flex;
    align-items: center;
}

.kt-grid__item .mt-help {
    margin-left: 24px;
    margin-right: -24px;
    width: 192px;
}

.help-context-close .mt-help {
    display: none;
}

.mt-help {
    &--actions {
        padding-top: 8px;
    }

    .kt-portlet {
        border-radius: 3px;
        border: 1px solid kt-base-color(border, 1);
    }

    .kt-portlet .kt-portlet__head {
        padding: 12px;
        min-height: unset;
    }

    .kt-portlet .kt-portlet__head .kt-portlet__head-label {

        .kt-portlet__head-title {
            font-size: 0.875rem !important;
            font-weight: 600;
            color: unset;
        }

        .kt-portlet__head-icon {
            padding-right: 0.75rem;
            font-size: 1.5rem;
            line-height: 0;
        }
    }

    .kt-portlet .kt-portlet__body {
        padding: 12px;
        font-size: 0.875rem;
        font-weight: 400;
    }

    p {
        margin-bottom: 0.5rem;
    }

    .mat-divider {
        margin: 12px -12px;
    }

    .ck-editor__top {
        display: none;
    }

    .ck-editor .ck-content {
        border: 2px solid;
        border-radius: 1px !important;
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border-color: #d3d3d9;
    }

    .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
        border: 2px solid;
    }
}

.mat-grey {
    color: #606062;
}

.mat-white {
    color: #ffffff;
}

.mat-menu-panel {
    min-height: 48px !important;
}

.mat-list-base.mt-selection-list {
    padding-bottom: 8px;

    .mat-list-option {
        height: 32px;

        .mat-list-text {
            font-size: 0.875rem;
            font-weight: 400;
        }
    }

    .mat-list-item {
        height: 32px;

        .mat-list-item-content {
            font-size: 0.875rem;
            font-weight: 400;
            justify-content: space-between;
        }
    }
}

.bg-confirmed {
    font-size: 0.875rem;
    padding: 6px 16px;
    width: 100%;
    color: #006620;
    background-color: #CFE3D6;
    border-radius: 5px;
}

.product-booking-field .mat-tab-header {
    display: none !important;
}

input.mat-input-element.form-control {
    margin-top: unset;
    background-color: #fff;
}

.dropdown-menu {
    /*border: 1px solid kt-base-color(border, 2);*/
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, .25), 0 0 1px rgba(9, 30, 66, .31);
}

.slide-toggle-center {
    margin: auto 0;
}

.no-border {
    border: none;

    .mat-table {
        padding: 0;
    }
}

/*.kt-aside .ps > .ps__rail-y > .ps__thumb-y {
    width: 5px;
}*/

.kt-portlet--mt {
    .cal-week-view .cal-all-day-events .cal-events-row {
        height: 34px;
    }

    .cal-week-view .see-more .cal-event {
        font-weight: 600;
        font-size: 12px;
        color: #017C38;
        text-shadow: unset;
        text-align: center;
        background-image: none !important;
    }

    .add-trip {
        font-size: 14px;
        line-height: 28px;
        color: #017C38;
        text-shadow: unset;
        text-align: center;
        background-image: none !important;

        .cal-event-title {
            border: dashed 1px #D3D3D9;
            border-radius: 10px;
            padding: 2px;
        }
    }

    .mt-calendar-wrapper.assign-trip {
        border-bottom: 1px solid #e1e1e1;
        min-width: 1366px;

        .cal-week-view .cal-day-headers {
            .custom-label {
                width: 181px;
                border-right: 1px solid #e1e1e1;
                padding: 5px;
            }
        }

        .cal-week-view .cal-events-row {
            width: calc(100% - 180px);
            margin-left: 180px;
        }

        .cal-week-view .person-container {
            width: 180px;
            padding: 5px;
            line-height: 21px;

            .person-name {
                font-weight: 600;
            }

            .person-phone {
                font-weight: 400;
                font-size: 0.875rem;
                color: #616161;
            }

            .person-add-trip {
                border: dashed 1px #D3D3D9;
                border-radius: 10px;
                padding: 2px !important;
                margin-top: 1px;
            }

            .kt-badge {
                font-size: 0.75rem;
                line-height: 1;
                padding: 0.15rem;

                .length-limit {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 70px;
                }
            }
        }

        .cal-time-events {
            border: none;
        }

        .cal-week-view .cal-all-day-events {
            border-bottom: 0;
        }
    }

    .cal-week-view .cal-event {
        color: #fff;
        text-shadow: 1px 1px #000;
        line-height: 14px;
        height: 32px;

        .kt-badge {
            line-height: 13px;
            min-height: 12px;
            min-width: 15px;
            margin: 0 1px;
        }

        .material-icons {
            font-size: 13px !important;
            vertical-align: middle;
        }

        .start-within {
            float: left;
            padding-top: 8px;
            padding-right: 2px;
        }

        .end-within {
            float: right;
            padding-top: 8px;
            padding-right: 2px;
        }

        .mt-badge {
            border-radius: 10px;
            border: dashed 1px #D3D3D9;
            padding: 0 2px 0 0;
            background-color: #fff;
            color: #017C38;
            text-shadow: 0 0 black;
            margin-left: 2px;

            &.over {
                color: #D32F2F;
            }

            &.lack {
                color: #616161;
            }
        }
    }

    .cal-week-view .cal-all-day-events .cal-event {
        padding-left: 2px;
    }

    .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent, transparent);

        .end-within {
            display: none;
        }
    }

    .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent, transparent);

        .start-within {
            display: none;
        }
    }

    .cal-week-view .cal-all-day-events .before-today .cal-event {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQYV43MQQ4AIAgDQfv/R4OYaFChsOfJYvQSNJxMgwouZDMGD2LwQhn8UARD9MIUeUjRhiUyqPw8Cgq7TDlmAAAAAElFTkSuQmCC);
    }

    .cal-week-view .cal-all-day-events .cal-starts-within-week.job-event .cal-event {
        background-color: #E5E5E5;
        background-image: none;
        color: #1F1F1F;
        text-shadow: none;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-color: #007BFF;
        border-width: medium;
    }

    .cal-week-view .cal-all-day-events .partial-schedule .cal-event {
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: 2px;
            clear: both;
            border-left: 10px solid transparent;
            border-bottom: 16px solid #fff;
            border-top: 16px solid #fff;
        }
    }

    .cal-week-view .cal-all-day-events .start-partial-schedule .cal-event {
        padding-left: 12px;

        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 2px;
            clear: both;
            border-left: 10px solid #fff;
            border-bottom: 16px solid transparent;
            border-top: 16px solid transparent;
        }
    }

    .cal-week-view .cal-day-headers {
        padding-left: 0;
    }

    .cal-week-view .cal-all-day-events .cal-events-row {
        margin-left: 0;
    }

    .cal-week-view .cal-all-day-events .cal-time-label-column {
        display: none;
    }

    .cal-week-view .cal-current-time-marker {
        background-color: unset;
    }

    .person-event-title {
        padding-top: 2px;
    }

    .person-event-title, .cal-event-title {
        .kt-checkbox {
            font-size: inherit;
            margin-bottom: inherit;
            padding-left: 16px;
        }

        .kt-checkbox > span {
            height: 14px;
            width: 14px;
            background: white;
        }

        .kt-checkbox > .checked:after {
            content: "";
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            margin-left: -2px;
            margin-top: -6px;
            width: 5px;
            height: 10px;
            border-width: 0 2px 2px 0 !important;
            transform: rotate(45deg);
        }

        .kt-checkbox > .checked:after {
            border: solid #ffffff;
        }
    }

    .checked-event {
        .person-event-title, .cal-event-title {
            .kt-checkbox > .checked:after {
                display: block;
            }

            .kt-checkbox > .checked {
                border: 1px solid #d3d3d9;
                background: #017C38;
            }
        }
    }
}

.mat-drawer-content {
    .mat-tab-header {
        height: 51px;
    }

    .mt-sidenav {
        width: kt-get($kt-aside-config, base, default, width);
        padding: 24px;
        overflow: visible;

        .mat-drawer-inner-container {
            overflow: visible;
        }

        .sidenav-title {
            font-size: 1.25rem;
            font-weight: 600;
            color: #606062;
            padding: 0px 0px 12px;
        }

        .sidenav-buttons {
            padding: 12px 0px;
        }

        .sidenav-content {
            display: flex;
            padding: 12px 0px;
            font-size: 0.875rem;
            align-items: center;

            .content-label {
                font-weight: 600;
                flex: 0 0 45%;
            }
        }
    }
}

mt-select-dropdown {

    .mat-list-base .mat-list-item .mat-list-item-content,
    .mat-list-base .mat-list-option .mat-list-item-content {
        padding: 0;
    }
}

@media (max-width: 1366px) {
    .col-mt-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.mt-booking-warning .mat-expansion-panel-body {
    background-color: #F5F5F5;
}

.mt-panel-title-row {
    display: flex;
    flex-wrap: wrap;

    .mt-panel-title-col {
        &--product {
            .mt-autocomplete-input.kt-input-icon {
                width: 287px;
            }
        }

        &--session-amount {
            display: flex;

            .totalamount {
                width: 134px;
            }
        }
    }
}

.kt-badge {
    text-shadow: none;
    line-height: inherit;
    min-height: 18px;
    min-width: 18px;
}

.mt-input-group > .form-control:not(:last-child),
.mt-input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.mt-input-group > .form-control:focus,
.mt-input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
}

.mt-session-summary-footer {
    background-color: #EBF5EF;
    padding: 8px 6px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #D3D3D9;
    margin-left: -17px;
    margin-right: -17px;

    &:last-child {
        margin-bottom: -8px;
    }
}

//new prices

.price-table .price-table-header {
    border: 1px solid #D3D3D9;
    padding: 10px;
}

.price-table .price-table-cell {
    border: 1px solid #D3D3D9;
    padding: 0px;
}

.price-table .price-table-cell input {
    border: none;
}

.price-table .price-table-cell span,
.price-table .price-table-cell-text {
    padding: 10px;
}

.price-table .price-table-cell {
    background: #FFF;
}

.price-table .price-table-header {
    background: #E0E0E0;
    font-weight: 400;
}

.price-table-action .mat-icon-button {
    width: 24px;
    height: 24px;
}

.price-table {
    width: auto; //100%;
    border-collapse: collapse;
}

.price-group-container .mat-expansion-panel,
.price-group-container .mat-expansion-panel-header,
.price-group-container .mt-expansion-panel .mat-expansion-panel-header.child {
    background: #F5F5F5 !important;
}

.price-group-container .mt-expansion-panel .mat-expansion-panel-header.child .mat-expansion-panel-header-title {
    color: #1F1F1F;
}

.price-mat-icon {
    vertical-align: unset;
}

.price-value-disable {
    opacity: 0.5;
}

.booking-seller > mt-select-dropdown > div > div.dropdown-menu.dropdown-menu-fit {
    //top: -150px !important;
    transform: translate(-110px, 37px) !important;
}

.kt-portlet--mt.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar {
    align-items: start;
}

.kt-form .kt-form__filtration,
.kt-form .kt-form__group-action {
    margin: 0 0 36px;
}

.kt-form .kt-form__filtration .dropdown--bg-white .single-select.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
    padding: 0px 8px;
}

.dropdown--bg-white {
    .dropdown {
        background: #FFFFFF;
        border: 1px solid #D3D3D9;
        border-radius: 4px;

        .dropdown-toggle {
            padding: 8px 0px 8px 8px !important;
            margin: 0px;
        }
    }
}

.modal-header {
    padding: 0.75rem;
    border: none;

    .modal-title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
    }

    .close {
        padding: 0.75rem 1rem;
    }
}

.modal-body {
    padding: 0.75rem;
    line-height: 1;

    .cal-event-container {
        cursor: pointer;
    }

    .cal-starts-without-week .cal-event .start-within {
        display: none;
    }

    .cal-end-without-week .cal-event .end-within {
        display: none;
    }

    .cal-event {
        height: 32px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent, transparent);

        .cal-event-title {
            color: white;
            font-weight: 600;
            font-size: 12px;
            padding: 4px;
            text-shadow: 1px 1px #000;

            .material-icons {
                font-size: 15px !important;
                vertical-align: middle;
                cursor: default;

                &.start-within {
                    padding: 6px 2px;
                    float: left;
                }

                &.end-within {
                    padding: 6px 2px;
                    float: right;
                }
            }

            .kt-badge {
                line-height: 15px;
                min-height: 15px;
                min-width: 15px;
            }
        }
    }
}

.mt-calendar-wrapper {
    &.cell-type-product {
        .cellType-sessionName {
            display: none;
        }
    }

    &.cell-type-session {
        .cellType-productName {
            display: none;
        }
    }
}

#dropdownBookingStatusMenu {
    transform: translate(0px, 34px) !important;
}

.kt-portlet__head-label .mt-filter-row .mat-icon-button--label .mat-icon {
    margin: 2px 2px;
}

.kt-portlet__head-label .mt-filter-row .mt-filter-sub .mat-icon-button--label .mat-icon {
    margin: 0px 2px;
    font-size: 20px;
    color: #606062;
    height: 20px;
    width: 20px;
}

.form-group span.form-control {
    height: fit-content;
    min-height: 34px;
}

.long-content {
    .dropdown-menu {
        min-width: 400px;
    }
}

.kt-portlet__body {
    min-height: 500px;
}

.mat-list-item-content {
    width: max-content;
}

.kt-form .kt-form__filtration .dropdown-menu .form-control {
    &--border {
        border: 1px solid #d3d3d9;
    }
}

.schedule-warning-panel {
    font-size: 0.813rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #FFF8EF;
    border: 1px solid #D3D3D9;

    .material-icons {
        font-size: 13px;
    }

    .mat-icon {
        vertical-align: middle;
        height: 16px;
        width: 13px;
        line-height: 1;
    }
}

@media (max-width: 1366px) {
    .mat-selection-list {
        max-height: 200px !important;
    }

    .assign-schedule {
        overflow: scroll;
        height: 80vh;
        width: 95vw;

        .kt-portlet__body {
            min-width: 1142px;
        }

        .mt-calendar-wrapper.assign-trip {
            min-width: 1142px;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .cal-day-headers .custom-label {
            background-color: #fff;
            position: sticky;
            left: 0;
            z-index: 2;
            width: 160px;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .person-container {
            background-color: #fff;
            position: sticky;
            left: 0;
            z-index: 2;
            margin: 0 !important;
            border-right: solid 1px #e1e1e1;
            width: 160px;
        }

        .cal-week-view .cal-day-headers .cal-header {
            flex: none;
            width: 140px;
        }

        .cal-week-view .cal-day-column {
            flex-grow: unset;
            width: 140px;
        }

        .cal-week-view .cal-all-day-events .cal-day-columns {
            z-index: unset;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .cal-events-row {
            width: calc(100% - 160px);
            margin-left: 160px;
        }
    }

    .calendar-schedule {
        overflow: scroll;
        height: 80vh;
        width: 95vw;

        .kt-portlet__body {
            min-width: 1072px;
        }

        .mt-calendar-wrapper.calendar-trip {
            min-width: 1072px;
        }
    }

    .approved-schedule {
        overflow: scroll;
        height: 80vh;
        width: 95vw;

        .kt-portlet__body {
            min-width: 1072px;
        }

        .mt-calendar-wrapper.assign-trip {
            min-width: 1072px;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .cal-day-headers .custom-label {
            background-color: #fff;
            position: sticky;
            left: 0;
            z-index: 2;
            width: 90px;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .person-container {
            background-color: #fff;
            position: sticky;
            left: 0;
            z-index: 2;
            margin: 0 !important;
            border-right: solid 1px #e1e1e1;
            width: 90px;
        }

        .cal-week-view .cal-day-headers .cal-header {
            flex: none;
            width: 140px;
        }

        .cal-week-view .cal-day-column {
            flex-grow: unset;
            width: 140px;
        }

        .cal-week-view .cal-all-day-events .cal-day-columns {
            z-index: 1;
        }

        .mt-calendar-wrapper.assign-trip .cal-week-view .cal-events-row {
            width: calc(100% - 90px);
            margin-left: 90px;
        }
    }
}

.mat-select-form-group {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        font-size: 0.875rem;
        padding: 16px 0 12px 0;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    .mat-form-field-infix {
        border-top: 0px;
    }

    .mat-select-arrow-wrapper {
        padding-top: 8px;
    }
}

.pdfContent {
    padding: 72px;

    .person-add-trip, .person-info {
        display: none;
    }

    &.approved-schedule {
        .mt-calendar-wrapper.assign-trip {
            overflow: unset;
            height: 100%;
            min-width: unset;
        }
    }
}

.kt-portlet.kt-portlet--mt .kt-portlet__head.kt-portlet__head--mt .kt-portlet__head-label .kt-portlet__head-title {
    width: max-content;
    padding-right: 8px;
}

.kt-mat-dialog-container__wrapper .mat-dialog-container .kt-portlet .kt-portlet__body {
    min-height: auto;
}
